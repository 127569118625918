import { AccountBalance, DashboardCustomize, TransferWithinAStation } from "@mui/icons-material";
import { Avatar, Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import WalletCard from "../common/walletCard";
import WalletChart from "../common/walletChart";
import WalletList from "../common/walletList";
import WalletTransaction from "../common/walletTransaction";
import { getUserInfo } from "../store/auth/auth.selector";
import { fetchTransactionRequest } from "../store/transaction/transaction.action";
import { getCoinPrices } from "../store/transaction/transaction.selector";
import { fetchCoinBalanceRequest } from "../store/user/user.action";
import { getCoinBalance, getStats, getUser } from "../store/user/user.selector";
export const pageSize = 9;

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coinBalance, setCoinBalance] = useState({
    XRP: 0,
    BTC: 0,
    BNB: 0,
    LTC: 0,
    BUSD: 0,
    USDT: 0,
    ETH: 0,
    TRX: 0,
  });
  const [currentBalance, setCurrentBalance] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  const { userInfo, stats, user, balance, prices } = useSelector((state) => {
    return {
      userInfo: getUserInfo(state),
      stats: getStats(state),
      user: getUser(state),
      balance: getCoinBalance(state),
      prices: getCoinPrices(state),
    };
  });

  useEffect(() => {
    if (selectedItem) {
      dispatch(fetchTransactionRequest(selectedItem));
      setCurrentBalance(coinBalance[selectedItem] || 0);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (prices.length > 0) {
      dispatch(fetchCoinBalanceRequest());
    }
  }, [prices]);

  useEffect(() => {
    if (balance.length > 0) {
      var x = {};
      balance.forEach((element) => {
        x[element.coinType] = parseFloat(element.coinBalance);
      });
      setCoinBalance(x);
    }
  }, [balance]);

  if (selectedItem) {
    return (
      <div>
        <WalletCard
          selectedItem={selectedItem}
          onClose={() => setSelectedItem("")}
          coinBalance={coinBalance}
          currentBalance={currentBalance}
        />
        <WalletTransaction selectedItem={selectedItem} />
      </div>
    );
  }

  return (
    <div>
      <Card className="mt-1">
        <CardHeader
          title={"Welcome " + userInfo.user.displayName}
          subheader={"Wallet Information"}
          avatar={<DashboardCustomize />}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card
                elevation={10}
                className="cursor"
                onClick={() => {
                  navigate("/deposits");
                }}
              >
                <CardHeader
                  title={stats.depositCount}
                  subheader="Deposits"
                  avatar={
                    <Avatar>
                      <AccountBalance color="primary" />
                    </Avatar>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card
                elevation={10}
                className="cursor"
                onClick={() => {
                  navigate("/withdraws");
                }}
              >
                <CardHeader
                  title={stats.withdrawCount}
                  subheader="Withdraws"
                  avatar={
                    <Avatar>
                      <TransferWithinAStation color="secondary" />
                    </Avatar>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <WalletChart coinBalance={coinBalance} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <WalletList
                coinBalance={coinBalance}
                onChange={(item) => setSelectedItem(item)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default Dashboard;
