import { call, put, select, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { getUserUID } from "../auth/auth.selector";
import * as TransactionActions from "./transaction.action";
import TransactionAPI from "./transaction.api";
import { TransactionActionTypes } from "./transaction.model";

export function* handleFetchTransaction(action) {
  try {
    const user = yield select(getUserUID);
    const response = yield call(
      TransactionAPI.GetTransactions,
      action.payload,
      user
    );
    yield put(TransactionActions.fetchTransactionSuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchTransactionFailure(e));
  }
}

export function* handleFetchPrices() {
  try {
    const response = yield call(TransactionAPI.GetPrices);
    yield put(TransactionActions.fetchPricesSuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchPricesFailure(e));
  }
}

export function* handleTransferCoins(action) {
  try {
    const response = yield call(TransactionAPI.TransferCoins, action.payload);
    yield put(TransactionActions.transferCoinSuccess(response));
    yield put(TransactionActions.fetchPricesRequest());
    Swal.fire({
      title: "Transfer Coins",
      text: `${action.payload.coins} ${action.payload.type} transferred successfully to ${action.payload.address}`,
      icon: "success",
    });
  } catch (e) {
    yield put(TransactionActions.transferCoinFailure(e));
  }
}

export function* handleCreateOrder(action) {
  try {
    const response = yield call(TransactionAPI.CreateOrder, action.payload);
    yield put(TransactionActions.createOrderSuccess(response));
    Swal.fire({
      title: "Create Order",
      text: `Order Created Successfully`,
      icon: "success",
    });
  } catch (e) {
    yield put(TransactionActions.createOrderFailure(e));
  }
}

export function* TransactionSaga() {
  yield takeEvery(
    TransactionActionTypes.FETCH_TRANSACTION_REQUEST,
    handleFetchTransaction
  );
  yield takeEvery(
    TransactionActionTypes.FETCH_PRICES_REQUEST,
    handleFetchPrices
  );
  yield takeEvery(
    TransactionActionTypes.TRANSFER_COINS_REQUEST,
    handleTransferCoins
  );
  yield takeEvery(
    TransactionActionTypes.CREATE_ORDER_REQUEST,
    handleCreateOrder
  );
}
