import { collection, getDocs, limitToLast, orderBy, query, where } from "firebase/firestore";
import Swal from "sweetalert2";
import { defaultDB } from "../../helpers/firebase/config";

class WhitelistAPI {
  static async GetWhitelists(uid) {
    const limit = 1000;
    let collectionRef = collection(defaultDB, "whitelists");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }
}

export default WhitelistAPI;
