import {
  collection,
  getDocs,
  limitToLast,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import Swal from "sweetalert2";
import { defaultDB } from "../../helpers/firebase/config";
import HttpHelper from "../../helpers/httpHelper";
import { getWebhookDepositUrl } from "./deposit.uri";

class DepositAPI {
  static async GetDeposits(req, uid) {
    let { status, startDate, endDate } = req;
    let limit = 1000;
    startDate = new Date(moment(startDate).startOf("day"));
    endDate = new Date(moment(endDate).endOf("day"));

    let collectionRef = collection(defaultDB, "deposits");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("type", "==", "BNB"),
      where("createdOn", ">=", startDate),
      where("createdOn", "<=", endDate),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async SendHook(id, hash, address) {
    let url = getWebhookDepositUrl(id, hash, address);
    return HttpHelper.httpRequest(url, "PATCH", {});
  }
}

export default DepositAPI;
