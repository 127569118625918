export const DepositActionTypes = {
    FETCH_DEPOSIT_REQUEST: '@@deposit/FETCH_DEPOSIT_REQUEST',
    FETCH_DEPOSIT_SUCCESS: '@@deposit/FETCH_DEPOSIT_SUCCESS',
    FETCH_DEPOSIT_FAILURE: '@@deposit/FETCH_DEPOSIT_FAILURE',

    RESTART_DEPOSIT_REQUEST: '@@deposit/RESTART_DEPOSIT_REQUEST',
    RESTART_DEPOSIT_SUCCESS: '@@deposit/RESTART_DEPOSIT_SUCCESS',
    RESTART_DEPOSIT_FAILURE: '@@deposit/RESTART_DEPOSIT_FAILURE',
}

export const depositInitialState = {
    deposits: [],
    isLoading: false
}