import { collection, getDocs, limitToLast, orderBy, query, where } from "firebase/firestore";
import Swal from "sweetalert2";
import { defaultDB } from "../../helpers/firebase/config";
import HttpHelper from "../../helpers/httpHelper";
import {
  createOrderUrl,
  getPriceAPIUrl,
  getTokenBalanceUrl,
  getTransferCoinUrl,
  getTransferTokensUrl
} from "./transaction.uri";

class TransactionAPI {
  static async GetTransactions(type, uid) {
    let limit = 1000;
    let collectionRef = collection(defaultDB, "transactions");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async GetTokenBalances(tokenAddresses) {
    let url = getTokenBalanceUrl();
    return HttpHelper.httpRequest(
      url,
      "POST",
      {},
      {
        tokens: tokenAddresses,
      }
    );
  }

  static async GetPrices() {
    let url = getPriceAPIUrl();
    return HttpHelper.httpRequest(url, "GET", {});
  }

  static async TransferCoins(req) {
    let url = getTransferCoinUrl(req.address, req.type, req.coins);
    if (req.tokenAddress) {
      url = getTransferTokensUrl(
        req.address,
        req.type,
        req.coins,
        req.tokenAddress
      );
    }
    return HttpHelper.httpRequest(url, "POST", {});
  }

  static async CreateOrder(req) {
    let url = createOrderUrl();
    return HttpHelper.httpRequest(url, "POST", {}, req);
  }
}

export default TransactionAPI;
