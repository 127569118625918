export const API_URL = process.env.REACT_APP_API_URL;

export function getDepositUrl(status, startDate, endDate) {
  let curUrl = API_URL + "/deposits?mode=view";
  if (status) {
    curUrl = curUrl + "&status=" + status;
  }
  if (startDate) {
    curUrl = curUrl + "&startDate=" + startDate;
  }
  if (endDate) {
    curUrl = curUrl + "&endDate=" + endDate;
  }
  return curUrl;
}

export function getWebhookDepositUrl(id, hash, fromAddress) {
  return API_URL + `/deposit/hook/${id}?hash=${hash}&fromAddress=${fromAddress}`;
}
