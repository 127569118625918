import { DepositActionTypes, depositInitialState } from "./deposit.model";

const reducer = (state = depositInitialState, action) => {
  switch (action.type) {
    case DepositActionTypes.FETCH_DEPOSIT_REQUEST:
    case DepositActionTypes.RESTART_DEPOSIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DepositActionTypes.FETCH_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deposits: action.payload,
      };

    case DepositActionTypes.RESTART_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deposits: state.deposits.map((t) => {
          if (t.id === action.payload.id) {
            t.fromAddress = action.payload.fromAddress;
            t.transactionhash = action.payload.hash;
            t.status = "Approved";
          }
          return t;
        }),
      };

    case DepositActionTypes.FETCH_DEPOSIT_FAILURE:
    case DepositActionTypes.RESTART_DEPOSIT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export { reducer as DepositReducer };

