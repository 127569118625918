import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  CalendarToday,
  CalendarViewDay,
  Close,
  Description,
  Email,
  FileCopy,
  Fingerprint,
  HourglassEmptyRounded,
  Key,
  Output,
  Phone,
  Search,
  Visibility
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  TextField
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import _ from "underscore";
import Loader from "../common/loader";
import { IsNullOrEmpty, getLogo, pageSize, paginate } from "../helpers/utility";
import { fetchWhitelistRequest } from "../store/whitelist/whitelist.action";
import { getWhitelists } from "../store/whitelist/whitelist.selector";

function Whitelist() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("All");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [datum, setDatum] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(fetchWhitelistRequest());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (sta) => {
    setAnchorEl(null);
  };

  const { whitelists, isLoading } = useSelector((state) => {
    return {
      whitelists: getWhitelists(state),
      isLoading: state.WhitelistReducer.isLoading,
    };
  });

  useEffect(() => {
    if (selectedItem) {
      let z = _.find(whitelists, (x) => x.id === selectedItem);
      setDatum(z);
    } else {
      setDatum({});
    }
  }, [selectedItem]);

  let filteredList = whitelists;
  if (!filteredList) {
    filteredList = [];
  }

  if (status !== "All") {
    filteredList = filteredList.filter((x) => x.isActive === status);
  }

  if (!IsNullOrEmpty(searchText)) {
    filteredList = filteredList.filter(
      (x) =>
        x.customerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.customerId.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.customerEmail.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.address.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.coinType.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
  }

  if (!IsNullOrEmpty(filter)) {
    let startTime = moment().startOf("day");
    let endTime = moment().endOf("day");
    switch (filter) {
      case "Today":
        filteredList = filteredList.filter((x) => {
          return moment
            .unix(x.createdOn.seconds)
            .isBetween(startTime, endTime);
        });
        break;
      case "Week":
        startTime = moment().startOf("weeks");
        endTime = moment().endOf("weeks");
        filteredList = filteredList.filter((x) => {
          return moment
            .unix(x.createdOn.seconds)
            .isBetween(startTime, endTime);
        });
        break;
      case "Month":
        startTime = moment().startOf("month");
        endTime = moment().endOf("month");
        filteredList = filteredList.filter((x) => {
          return moment
            .unix(x.createdOn.seconds)
            .isBetween(startTime, endTime);
        });
        break;
      case "Year":
        startTime = moment().startOf("year");
        endTime = moment().endOf("year");
        filteredList = filteredList.filter((x) => {
          return moment
            .unix(x.createdOn.seconds)
            .isBetween(startTime, endTime);
        });
        break;
    }
  }

  filteredList = _.sortBy(filteredList, "createdOn").reverse();
  let totalPages = Math.ceil(filteredList.length / pageSize);
  filteredList = paginate(filteredList, pageSize, page);

  return (
    <div>
      {isLoading && <Loader />}
      <Card className="mt-1">
        <CardHeader
          title="Whitelist Wallets"
          subheader={"List of Whitelist Wallets"}
          avatar={<Key />}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                className="rounded-lg"
                fullWidth
                style={{ marginTop: 0 }}
                margin="dense"
                size="small"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                native
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                value={filter}
                onChange={(e) => [setFilter(e.target.value)]}
              >
                <option value="">All Time</option>
                <option value="Today">Today</option>
                <option value="Week">This Week</option>
                <option value="Month">This Month</option>
                <option value="Year">This Year</option>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {filteredList.map((dep) => {
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <img
                              style={{ height: 32, width: 32 }}
                              src={getLogo(dep.coinType).logo}
                            />
                          }
                          classes={{
                            root: "p-1",
                            action: "px-3 py-2",
                          }}
                          title={dep.customerId}
                          subheader={dep.customerName}
                          action={
                            <Visibility
                              color="secondary"
                              onClick={() => setSelectedItem(dep.id)}
                            />
                          }
                        />
                        <Divider />
                        <CardContent className="p-0">
                          <table className="table-striped text-medium border-none">
                            <tr>
                              <td>Min Coins</td>
                              <td>{`${dep.minCoins} ${
                                dep.tokenSymbol || dep.coinType
                              }`}</td>
                            </tr>
                            <tr>
                              <td>Max Coins</td>
                              <td>{`${dep.maxCoins} ${
                                dep.tokenSymbol || dep.coinType
                              }`}</td>
                            </tr>
                            <tr>
                              <td>Created On</td>
                              <td>
                                {moment
                                  .unix(dep.createdOn.seconds)
                                  .format("lll")}
                              </td>
                            </tr>
                          </table>
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            size="small"
                            disableElevation
                          >
                            {dep.isActive ? "Active" : "In-active"}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid xs={12} sm={12}>
              {filteredList.length > 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="my-2"
                >
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    count={totalPages}
                    page={page}
                    color="primary"
                    onChange={(e, pg) => {
                      setPage(pg);
                    }}
                  />
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  height="100%"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="my-2"
                >
                  <HourglassEmptyRounded
                    color="secondary"
                    fontSize="large"
                    style={{ fontSize: 64 }}
                  />
                  <p>No data available</p>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {datum.id && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={!IsNullOrEmpty(selectedItem) && datum.id}
        >
          <DialogTitle>
            Whitelist Information
            <Close
              className="float-right mt-2"
              onClick={() => {
                setSelectedItem("");
              }}
            />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer ID"
                      primary={datum.customerId}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.customerId);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBox />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Name"
                      primary={datum.customerName}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.customerName);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Email />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Email"
                      primary={datum.customerEmail}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.customerEmail);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Phone />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Mobile"
                      primary={datum.customerPhone}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.customerPhone);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalance />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Min Coins"
                      primary={`${datum.tokenSymbol || datum.coinType} ${
                        datum.minCoins
                      }`}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.coins);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalanceWallet />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Max Coins"
                      primary={`${datum.tokenSymbol || datum.coinType} ${
                        datum.maxCoins
                      }`}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.maxCoins);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Address"
                      primary={datum.address}
                      primaryTypographyProps={{
                        className: "overflow-text",
                      }}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.address);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Output />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Status"
                      primary={datum.isActive ? "Active" : "In-Active"}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Created On"
                      primary={moment
                        .unix(datum.createdOn.seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment
                                .unix(datum.createdOn.seconds)
                                .format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarViewDay />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Last Modified On"
                      primary={moment
                        .unix(datum.updatedOn.seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment
                                .unix(datum.updatedOn.seconds)
                                .format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Whitelist;
