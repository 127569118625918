import { action } from "typesafe-actions";
import { DepositActionTypes } from "./deposit.model";

export const fetchDepositRequest = (req) => action(DepositActionTypes.FETCH_DEPOSIT_REQUEST, req);
export const fetchDepositSuccess = (res) => action(DepositActionTypes.FETCH_DEPOSIT_SUCCESS, res);
export const fetchDepositFailure = (err) => action(DepositActionTypes.FETCH_DEPOSIT_FAILURE, err);

export const restartDepositRequest = (req) => action(DepositActionTypes.RESTART_DEPOSIT_REQUEST, req);
export const restartDepositSuccess = (res) => action(DepositActionTypes.RESTART_DEPOSIT_SUCCESS, res);
export const restartDepositFailure = (err) => action(DepositActionTypes.RESTART_DEPOSIT_FAILURE, err);
