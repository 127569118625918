import { call, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import * as UserActions from "./user.action";
import UserAPI from "./user.api";
import { UserActionTypes } from "./user.model";

export function* handleUpdateUser(action) {
  try {
    const response = yield call(UserAPI.UpdatePin, action.payload);
    yield put(UserActions.updateUserSuccess(response));
    Swal.fire({
      title: "Updated Settings",
      text: "Settings updated successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(UserActions.updateUserFailure(e));
  }
}

export function* handleVerifyPin(action) {
  try {
    const response = yield call(UserAPI.VerifyPin, action.payload, false);
    yield put(UserActions.verifyPinSuccess(response));
    yield put(UserActions.fetchCoinBalanceRequest());
  } catch (e) {
    Swal.fire({
      title: "Invalid PIN",
      text: "Invalid PIN. Please enter correct pin",
      icon: "error",
    });
    yield put(UserActions.verifyPinFailure(e));
  }
}

export function* handleUpdateKey() {
  try {
    const response = yield call(UserAPI.GenerateNewKey);
    yield put(UserActions.updateKeySuccess(response));
    Swal.fire({
      title: "Merchant Key",
      text: "Merchant Key Generated Successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(UserActions.updateKeyFailure(e));
  }
}

export function* handleGetStats() {
  try {
    const response = yield call(UserAPI.GetStats);
    yield put(UserActions.getStatisticsSuccess(response));
  } catch (e) {
    yield put(UserActions.getStatisticsFailure(e));
  }
}

export function* handleCoinBalance(action) {
  try {
    const response = yield call(UserAPI.GetCoinBalnce);
    yield put(UserActions.fetchCoinBalanceSuccess(response));
  } catch (e) {
    yield put(UserActions.fetchCoinBalanceFailure(e));
  }
}

export function* handleUpdateSetting(action) {
  try {
    const response = yield call(UserAPI.UpdateSetting, action.payload);
    yield put(UserActions.updateSettingsSuccess(response));
    Swal.fire({
      title: "Updated Settings",
      text: "Settings updated successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(UserActions.updateSettingsFailure(e));
  }
}

export function* handleUpdateToken(action) {
  try {
    const response = yield call(UserAPI.UpdateTokens, action.payload);
    yield put(UserActions.updateTokenSuccess(response));
  } catch (e) {
    yield put(UserActions.updateTokenFailure(e));
  }
}

export function* handleBNBTokenBalance(action) {
  try {
    let response = yield call(UserAPI.GetBNBWalletAddressBal, action.payload);
    response.address = action.payload.tokenAddress;
    yield put(UserActions.getTokenBNBBalSuccess(response));
  } catch (e) {
    yield put(UserActions.getTokenBNBBalFailure(e));
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, handleUpdateUser);
  yield takeEvery(UserActionTypes.VERIFY_PIN_REQUEST, handleVerifyPin);
  yield takeEvery(UserActionTypes.UPDATE_KEY_REQUEST, handleUpdateKey);
  yield takeEvery(UserActionTypes.GET_STATUS_REQUEST, handleGetStats);
  yield takeEvery(UserActionTypes.FETCH_BALANCE_REQUEST, handleCoinBalance);
  yield takeEvery(UserActionTypes.UPDATE_SETTINGS_REQUEST, handleUpdateSetting);
  yield takeEvery(UserActionTypes.UPDATE_TOKEN_REQUEST, handleUpdateToken);
  yield takeEvery(UserActionTypes.GET_TOKEN_BAL_REQUEST, handleBNBTokenBalance);
}
